.custom-img {
    background-image: url("https://images.unsplash.com/photo-1454496522488-7a8e488e8606?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1955&q=80");
}

.fullpage {
    display: flex;
    overflow: auto;
    flex: none;

    flex-flow: column nowrap;

    scroll-snap-type: y mandatory;
}

.fullpage>div {
    scroll-snap-align: center;
    flex: none;
}

.container input[type="checkbox"]:checked~div {
    text-decoration: line-through;
    color: rgb(185 28 28 / var(--tw-text-opacity));
}

.container input[type="checkbox"] {
    visibility: hidden;
}

.fade-in2 {
    animation: fadein2 2s forwards;
}

@keyframes fadein2 {
    0% {
        filter: opacity(0);
    }

    100% {
        filter: opacity(1);
    }
}

div.scroll {
    height: 150px;
    overflow-x: hidden;
    overflow-y: auto;
}

.taskinputs input:focus {
    outline: none !important;
    background:
        linear-gradient(#4c4c4c, #c6c6c6) center bottom 0px /calc(100% - 1px) 1px no-repeat;
    background-color: #ffffff;
    border: none;
    transition: 0s;
}